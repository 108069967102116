<template>
  <ThePage :class="theme">
    <slot />
  </ThePage>
</template>

<script setup>
const appConfig = useAppConfig();
const route = useRoute();

const theme = computed(() => {
  const [, themeRoute] = route.href.split(/\/|\?/);
  return appConfig?.theme?.[themeRoute] || appConfig?.theme?.default || 'theme-matr';
});
</script>
