<template>
  <button
    :class="[
      'button-burger',
      { 'button-burger--open': active },
    ]"
    alt="Bouton Menu mobile"
    name="Bouton Menu mobile"
  >
    <div>Menu mobile</div>
    <span class="lines">
      <span class="line line--top" />
      <span class="line line--middle" />
      <span class="line line--bottom" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>

<style lang="stylus" scoped>
div {
  display: none;
}

$burger-line-width = 20px
$burger-line-small-width = 16px
$burger-space = 7px

.button-burger {
  @extends $reset-button !optional

  display flex
  justify-content center
  align-items center
  cursor pointer
}

.line {
  display block
  width $burger-line-width
  height 1px
  margin auto
  background-color currentColor

  transition 0.35s ease-in
  transform-origin center center
}

.line--top {
  width $burger-line-small-width
  margin-bottom $burger-space

  .button-burger--open & {
    transform s('translate3d(0, %s, 0)', ($burger-space + 1px)) rotate(-45deg)
  }
}

.line--bottom {
  width 16px
  margin-top $burger-space

  .button-burger--open & {
    transform s('translate3d(0, %s, 0)', -($burger-space + 1px)) rotate(45deg)
  }
}

.line--middle {
  transform-origin left center
  .button-burger--open & {
    transform scaleX(0)
  }
}
</style>
