<template>
  <LayoutFooter class="footer" :version="$config?.public?.version">
    <template #section1>
      <AddressPin class="footer__address desktop-only" />

      <ClientOnly>
        <div class="footer__eco">
          🌱&nbsp;&nbsp;La visite de cette page a émis {{ co2.route }}g de CO2&nbsp;-&nbsp;
          <NuxtLink v-if="config.co2" class="footer__link-underline" @click="$emit('openCO2')">
            En&nbsp;savoir&nbsp;plus
          </NuxtLink>
        </div>
      </ClientOnly>
    </template>

    <template #section2>
      <LayoutVerticalNav class="footer__nav">
        <template #logo>
          <img
            class="footer__logo"
            src="~assets/images/logo-vertical@2x.png"
            alt="Logo Matrice"
            width="100"
            height="93"
          >
        </template>

        <template #column1>
          <NuxtLink
            v-for="item, index in config.main_navigation"
            :key="'main-link-'+index"
            :to="item.url"
            :target="item.target"
            class="footer__link"
          >
            {{ item.label }}
          </NuxtLink>
        </template>

        <template #column2>
          <NuxtLink
            v-for="item, index in config.footer_navigation"
            :key="'footer-link-'+index"
            :to="item.url"
            :target="item.target"
            class="footer__link"
          >
            {{ item.label }}
          </NuxtLink>
          <NuxtLink class="footer__link" @click="$emit('openContact')">
            Contacts
          </NuxtLink>
          <NuxtLink class="footer__link" @click="$emit('openPartners')">
            Partenaires
          </NuxtLink>
        </template>
      </LayoutVerticalNav>

      <Socials class="footer__socials mobile-only" />

      <Register class="footer__register" />
    </template>

    <template #section3>
      <NuxtImg
        v-for="(item, index) in config.partners?.certifications?.data"
        :key="`footer__certif-${index}`"
        :image="item"
        :src="item.attributes.url"
        :alt="item.attributes.alternativeText"
        :height="Math.round((item.attributes.height/item.attributes.width)*100)"
        width="100"
        class="footer__certifications"
      />
      <Socials class="footer__socials desktop-only" />
    </template>

    <template #section4>
      <AddressPin class="footer__address mobile-only" />

      <nav class="footer__bottom-nav">
        <NuxtLink
          v-for="item, index in config.legals_navigation"
          :key="'legals-link-'+index"
          :to="item.url"
          :target="item.target"
          class="footer__link"
        >
          {{ item.label }}
        </NuxtLink>

        <p
          v-if="$config?.public?.version"
          class="footer__version"
        >
          version {{ $config.public.version }}
        </p>
      </nav>
    </template>
  </LayoutFooter>
</template>

<script setup>
  defineEmits(['openPartners', 'openCO2', 'openContact']);
  const {data:config} = await useAPIConfig();
  const co2 = useCO2();
</script>

<style lang="stylus" scoped>
.mobile-only {
  +size(M) {
    display none
  }
}

.desktop-only {
  display none

  +size(M) {
    display inherit
  }
}

.footer {
  color var(--theme-color-text, black)
  background-color $cl-flat
}

.footer__logo {
  max-width 100px
  margin-bottom 25px
}

.footer__eco {
  width 100%
  text-align center
}

.footer__eco,
.footer__address {
  +size(M) {
    width auto
    text-align left
  }
}

.footer__link-underline {
  color var(--theme-color-text, black)
  text-decoration underline
  cursor pointer
  &:hover {
    text-decoration none
  }
}

.footer__link {
  color var(--theme-color-text, black)
  text-decoration none
  margin-bottom 20px
  cursor pointer
  display inline-block

  &:last-child {
    margin-bottom 0
  }

  .footer__bottom-nav & {
    margin-right 30px
  }

  hover-underline()
}

.footer__bottom-nav {
  display flex
  flex-direction column
  align-items flex-start
  width 100%

  +size(M) {
    flex-direction row
  }
}

.footer__certifications {
  width 100px;
  margin-right 30px;
}

.footer__register {
  width 100%

  +size(M) {
    max-width 380px
  }
}

.footer__socials {
  width 100%
  max-width 460px
  margin 50px auto

  +size(M) {
    justify-content flex-end
    max-width none
    gap 40px
  }
}

:deep(.footer-layout__section-wrapper) {
  border-top 1px solid $cl-separator
}

:deep(.footer-layout__section) {
  @extends .app-gutter, .app-width-large

  +size(M) {
    flex-direction row
    align-items center
  }
}

:deep(.footer-layout__section--0) {
  padding-top 10px
  padding-bottom @padding-top

  +size(M) {
    padding-top 20px
    padding-bottom @padding-top
  }
}

:deep(.footer-layout__section--1) {
  padding-top 50px
  padding-bottom @padding-top

  +size(M) {
    padding-top 75px
    padding-bottom 90px
  }
}

:deep(.footer-layout__section--2) {
  padding-top 10px
  padding-bottom @padding-top

  +size(M) {
    padding-top 25px
    padding-bottom @padding-top
  }
}

:deep(.footer-layout__section--3) {
  padding-top 25px
  padding-bottom @padding-top

  +size(M) {
    padding-top 20px
    padding-bottom 100px
  }

  .footer__address {
    margin-bottom 25px
  }
}

:deep(.footer-layout__section--version)
.footer__version {
  opacity 0.5
  margin 0 auto
  +size(M) {
    margin-right 0
  }
}

</style>
