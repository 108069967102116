<template>
  <footer class="footer-layout">
    <div
      v-for="(name, index) in Object.keys($slots)"
      :key="`footer-item-${index}`"
      class="footer-layout__section-wrapper"
    >
      <div :class="['footer-layout__section', `footer-layout__section--${index}`]">
        <slot :name="[name]" />
      </div>
    </div>
  </footer>
</template>

<style lang="stylus" scoped>
.footer-layout {
  position relative
}

.footer-layout__section {
  display flex
  flex-direction column
  justify-content space-between
  align-items flex-start
}
</style>
