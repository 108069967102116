<template>
  <!-- eslint-disable vue/v-on-event-hyphenation -->
  <div class="page">
    <TheNavigation class="page__nav" />

    <TheLogo class="page__logo" />

    <div class="page__content">
      <slot />
    </div>

    <TheCookies />
    <TheFooter
      class="page__footer"
      @open-partners="showPartners = true"
      @open-CO2="showCO2 = true"
      @open-contact="showContact = true"
    />

    <UiButtonCube
      class="page__contact"
      type="mail"
      alt="Nous contacter"
      name="Nous contacter"
      @click="showContact = true"
    />

    <div class="page__panel" />

    <ThePartners v-if="showPartners" @close="showPartners = false" />
    <TheCO2 v-if="showCO2" @close="showCO2 = false" />
    <TheContact v-if="showContact" @close="showContact = false" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPartners: false,
      showCO2: false,
      showContact: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
$isNavFixed = true

.page__content {
  box-sizing border-box
  overflow hidden
  overflow clip // fix sticky list
}

.page__contact {
  position fixed
  bottom 40px
  right @bottom
  z-index 1

  --theme-color black
  color white

  cursor pointer
  transition transform 0.25s

  &:hover {
    transform scale(1.1)
  }
}

.page__panel {
  position fixed
  right 0
  top 0
  z-index 10

  display flex

  width 400px
  max-width 98%
  max-height 100vh
  overflow hidden
}

if $isNavFixed {
  /* fixed nav override */
  $navigation-layout-breakpoint ?= 780px

  .page__nav {
    position fixed
    width 100%
    z-index 10
    top 0
    left 0
    right 0
  }

  .page__logo {
    :deep(.logo__link) {
      position relative
      z-index 20
    }
  }

  :deep(.nav-layout__logo) {
    opacity 0
  }

  :deep(.nav-layout--open) {
    background-color white
    z-index 30
    +size($navigation-layout-breakpoint) {
      background-color transparent
    }
  }

  :deep(.nav-layout--open .nav-layout__logo) {
    opacity 1
    +size($navigation-layout-breakpoint) {
      opacity 0
    }
  }
}

if !$isNavFixed {
  .page__nav {
    position relative
    z-index 1
  }

  .page__logo {
    display none
  }
}
</style>