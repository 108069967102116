<template>
  <div class="v-nav-layout">
    <div v-if="$slots.logo" class="v-nav-layout__column v-nav-layout__logo">
      <slot name="logo" />
    </div>

    <div v-for="column, index in columnSlots" :key="`column-${index}`" class="v-nav-layout__column">
      <slot :name="[column]" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    columnSlots() {
      return Object.keys(this.$slots).filter(name => name !== 'logo');
    },
  },
};
</script>

<style lang="stylus" scoped>
$vertical-navigation-layout-breakpoint ?= 780px
.v-nav-layout {
  display flex
  flex-wrap wrap
}

.v-nav-layout__logo {
  width 100%
  @media(min-width: $vertical-navigation-layout-breakpoint) {
    width auto
  }
}

.v-nav-layout__column {
  display flex
  flex-direction column
  align-items flex-start
  margin-right 100px
}
</style>
