<template>
  <LayoutNavigation v-if="data.main_navigation" ref="layout" class="nav app-width-large">
    <template #logo>
      <img
        class="nav__logo"
        src="~assets/images/logo@2x.png"
        alt="Logo Matrice"
        width="190"
        height="68"
      >
    </template>

    <template #links>
      <UiButtonNav
        v-for="item, index in data.main_navigation"
        :key="'nav-item-'+index"
        :to="item.url"
        class="nav__link"
      >
        {{ item.label }}
      </UiButtonNav>
    </template>

    <template #burger>
      <UiButtonBurger class="nav__burger" :active="$refs.layout && $refs.layout.isOpen" />
    </template>
  </LayoutNavigation>
</template>

<script setup>
  const {data} = await useAPIConfig();
</script>

<style lang="stylus" scoped>
.nav {
  height 70px
  +size($navigation-layout-breakpoint) {
    height 80px
  }
}

.nav__link {
  height 100%
  color var(--theme-color-text, white)
}

.nav__logo {
  display block
  width 140px
  height auto
  +size($navigation-layout-breakpoint) {
    width 190px
  }
}

.nav__burger {
  width 70px
  height @width
  color var(--theme-color-text, white)
  background-color var(--theme-color, black)
  border-bottom-left-radius 7px
}

:deep(.nav-layout__logo) {
  @extends .app-gutter
}

:deep(.nav-layout__links) {
  @extends .app-gutter
  border-bottom-left-radius 7px

  +size($navigation-layout-breakpoint) {
    background-color var(--theme-color, black)
  }
}
</style>
