<template>
  <Teleport to=".page__panel">
    <ThePanel class="partners" @close="$emit('close')">
      <div class="partners__detail">
        <UiMediaImage
          v-for="(certif, index) in certifications"
          :key="index"
          :image="certif"
          :quality="35"
          :width="212"
          :height="Math.round((certif.height/certif.width)*212)"
          fit
          class="partners__top-logo"
        />

        <template v-for="(group, groupIndex) in groups" :key="groupIndex">
          <p class="partners__title typo-h2">
            {{ group.title }}
          </p>

          <div class="partners__list">
            <UiMediaImage
              v-for="partner in group.partners"
              :key="partner.slug"
              :image="partner.logo"
              :options="{ fit: 'contain', background: '#FFFFFF' }"
              :quality="35"
              width="150"
              ratio="1"
              contain
              class="partners__logo"
            />
          </div>
        </template>
      </div>
    </ThePanel>
  </Teleport>
</template>

<script setup>
import { getMediaFromData } from '~/data/media';
import { getPartnerFromData } from '~/data/partner';

defineEmits(['close']);

const {data:config} = await useAPIConfig();

const certifications = computed(() => {
  const data = config?.value.partners;
  if (!data?.certifications?.data) return;

  if (Array.isArray(data.certifications.data)) {
    return data.certifications.data.map(cert => getMediaFromData({ data: cert }));
  }

  return [getMediaFromData(data.certifications)];
});

const groups = computed(() => {
  const data = config?.value.partners;
  if (!data?.groups) return;

  return data.groups.map(({ title, partners }) => ({
    title,
    partners: partners?.data?.map(partner => getPartnerFromData(partner)),
  }));
});
</script>

<style lang="stylus" scoped>
.partners {
  height: 100vh
}
.partners__logo {
  width 150px
  max-width 33vw
  min-height 90px
  aspect-ratio 1/1
  box-sizing border-box
  padding-left 25px
  padding-right @padding-left
  line-height 0
  border 1px solid $cl-separator
}

.partners__top-logo {
  width 212px
  max-width 100%
  margin-bottom 20px
}

.partners__title {
  margin 30px 0
}

.partners__list {
  display flex
  justify-content space-between
  flex-wrap wrap
  gap 25px
}

.partners__detail {
  box-sizing border-box
  padding 80px 35px 20px 35px
}
</style>
