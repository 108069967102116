<template>
  <Teleport to=".page__panel">
    <ThePanel class="co2" @close="$emit('close')">
      <div class="co2__detail">
        <h2>{{ data.co2.title || "Impacts Carbone" }}</h2>
        La visite de cette page a émis {{ co2.route }}g de CO2eq
        <br>
        Au total, votre visite a émis {{ co2.total }}g de CO2eq
      </div>
      <div v-dompurify-html="$md2Html(data.co2.content)" class="co2__content" />
    </ThePanel>
  </Teleport>
</template>
  
  <script setup>
  
  defineEmits(['close']);
  
  const {data} = await useAPIConfig();
  const co2 = useCO2();
  </script>
  
  <style lang="stylus" scoped>
  .co2 {
    height 100vh
    width 400px;
  }
  .co2__logo {
    width 150px
    max-width 33vw
    min-height 90px
    aspect-ratio 1/1
    box-sizing border-box
    padding-left 25px
    padding-right @padding-left
    line-height 0
    border 1px solid $cl-separator
  }
  
  .co2__top-logo {
    width 212px
    max-width 100%
    margin-bottom 20px
  }
  
  .co2__title {
    margin 30px 0
  }
  
  .co2__list {
    display flex
    justify-content space-between
    flex-wrap wrap
    gap 25px
  }
  
  .co2__detail {
    box-sizing border-box
    padding 80px 35px 20px 35px
  }

  .co2__content {
    box-sizing border-box
    padding 0px 35px 20px 35px
  }
  </style>
  