<template>
  <address v-if="data.address" class="address-pin">
    📍 {{ data.address.number }} {{ data.address.street }} - {{ data.address.zipcode }} {{ data.address.city }}
  </address>
</template>
<script setup>
  const {data} = await useAPIConfig();
</script>
<style lang="stylus" scoped>
.address-pin {
  font-style normal
}
</style>