/* eslint-disable no-undef */
import { co2 } from '@tgwf/co2';

/* Shared reactives */
const bytes = reactive({route: 0, total: 0});
const model = new co2();
const options = {
    gridIntensity: {
        // device:{country:"FRA"},
        networks: {country: "FRA"},
        dataCenter: {country: "FRA"},
    },
};
const ghg = computed(()=> {
    return {
        route: model.perByteTrace(bytes.route, false, options).co2.toFixed(2),
        total: model.perByteTrace(bytes.total+bytes.route, false, options).co2.toFixed(2),
    };
});


/* Helper function */
function updateBytes() {
    bytes.route = performance.getEntries().reduce((sum, entry)=> sum+= entry.transferSize || 0, 0);
}

/* Register events only once */
if(process.client){
    document.addEventListener("load", (event)=> {
        updateBytes();
    }, true);
}

export default function(){
    if(process.client){
        performance.setResourceTimingBufferSize(500);
        const router = useRouter();

        router.beforeEach((to, from)=>{
            bytes.total = bytes.route;
            performance.clearResourceTimings();
        });
        router.afterEach((to, from)=> {
            updateBytes();
        });


        return ghg;
    }
}