<template>
  <div class="logo app-width-large">
    <NuxtLink to="/" class="logo__link app-gutter">
      <img
        class="logo__pic"
        src="~assets/images/logo@2x.png"
        alt="Logo Matrice"
        width="190"
        height="68"
      >
      <span class="logo__label">Accueil</span>
    </NuxtLink>
  </div>
</template>

<style lang="stylus" scoped>
$navigation-layout-breakpoint ?= 780px

.logo {
  display flex
  align-items center
  height 70px
  +size($navigation-layout-breakpoint) {
    height 80px
  }
}

.logo__pic {
  display block
  width 140px
  height auto
  +size($navigation-layout-breakpoint) {
    width 190px
  }
}
.logo__label {
  overflow hidden
  display block
  width 0
  height 0
  opacity 0
}
</style>
