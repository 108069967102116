<template>
  <div class="socials">
    <UiButtonSocial type="facebook" class="socials__button" target="_blank" />
    <UiButtonSocial type="twitter" class="socials__button" target="_blank" />
    <UiButtonSocial type="linkedin" class="socials__button" target="_blank" />
    <UiButtonSocial type="instagram" class="socials__button" target="_blank" />
  </div>
</template>

<style lang="stylus" scoped>
.socials {
  display flex
  justify-content space-between
  align-items flex-end
}

.socials__button {
  :deep(img) {
    transition transform 0.1s ease-out
  }
  &:hover {
    :deep(img) {
      transform translateY(-3px)
    }
  }
}
</style>
