<template>
  <div v-if="config.mailchimp" class="register">
    <ClientOnly>
      <form class="register__form" :action="config.mailchimp.list_url" method="post">
        <label for="mce-EMAIL">Mon email</label>
        <input
          id="mce-EMAIL"
          class="typo-default"
          type="text"
          placeholder="Mon email"
          name="EMAIL"
        >
        <button id="mce-SUBMIT" class="typo-default typo-link" type="submit">
          {{ config.mailchimp.submit }}
        </button>
      </form>
    </ClientOnly>
    <p class="register__text">
      {{ config.mailchimp.description }}
      <br>
      <NuxtLink :to="config.mailchimp.archives.url" :target="config.mailchimp.archives.target">
        <b>{{ config.mailchimp.archives.label }}</b>
      </NuxtLink>
    </p>
  </div>
</template>

<script setup>
  const {data:config} = await useAPIConfig();
</script>

<style lang="stylus" scoped>
label {
  display:none
}
.register__form {
  display flex
  background-color white
  padding 5px
  border 1px solid black
  border-top-left-radius 7px

  input {
    @extends $reset-button !optional
    flex-grow 1
    padding-left 25px
    &:focus {
      outline none
    }
  }

  button {
    @extends $reset-button !optional
    background-color black
    color white
    padding 10px
    border-top-left-radius 7px
    cursor pointer
    &:hover {
      text-decoration underline
    }
  }
}
</style>
