<template>
  <NuxtLink v-bind="$props" :class="['button-nav', theme]">
    <span class="button-nav__label">
      <slot>label</slot>
    </span>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({ to: { type: String, default: null } });

const { theme: configTheme = null } = await useAppConfig();

const theme = computed(() => {
  if (!configTheme) return 'theme-matr';
  // if(props.to === "/" ) return 'theme-matr';
  if (!props.to) return configTheme.default || 'theme-matr';
  const [, themeRoute] = props.to.split('/');
  return configTheme[themeRoute] || configTheme.default || 'theme-matr';
});
</script>

<style lang="stylus" scoped>
desktop-version() {
  @media (min-width $navigation-layout-breakpoint) {
    { block }
  }
}

.button-nav {
  position relative

  display flex
  justify-content flex-start
  align-items center
  min-height 2em
  padding 15px 7px
  text-decoration none

  &:after {
    content ''
    position absolute
    top 15px
    bottom 15px
    left 0

    width 2px
    height auto

    background-color var(--theme-color, white)
    transition transform 0.2s
  }

  &.theme-home:after {
    background-color black  
  }

  +desktop-version() {
    justify-content center
    padding 0 15px

    &:after {
      top auto
      left 15px
      right 15px
      bottom 0

      width auto
      height 5px

      background-color white

      transform scale3d(0, 1, 1)
      transform-origin right center

      .page.theme-home & {
        background-color var(--theme-color, white)
      }
    }

    &.theme-home:after {
      background-color white
      .page.theme-home & {
        background-color black
      }
    }
  }

  &.router-link-active
  &:hover {
    &:after {
      transform scale3d(1, 1, 1)
      transform-origin left center
      transition transform 0.25s ease-out
    }
  }
}
</style>
