<template>
  <div :class="[ 'nav-layout', { 'nav-layout--open': isOpen } ]">
    <div class="nav-layout__wrapper">
      <NuxtLink to="/" class="nav-layout__logo">
        <slot name="logo" />
        <span class="nav-layout__home-label">Accueil</span>
      </NuxtLink>

      <div class="nav-layout__links">
        <slot name="links">
          <NuxtLink to="#here">
            here
          </NuxtLink>&nbsp;
          <NuxtLink to="#the">
            the
          </NuxtLink>&nbsp;
          <NuxtLink to="#navigation">
            navigation
          </NuxtLink>&nbsp;
          <NuxtLink to="#links">
            links
          </NuxtLink>&nbsp;
        </slot>
      </div>

      <div class="nav-layout__burger" @click="isOpen = !isOpen">
        <slot name="burger">
          <button>{{ isOpen ? 'x' : '&equiv;' }}</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    $route() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
$navigation-layout-breakpoint ?= 780px

desktop-version() {
  @media (min-width $navigation-layout-breakpoint) {
    { block }
  }
}
.nav-layout {
  box-sizing border-box
  height 100px
}

.nav-layout__wrapper {
  position relative

  display flex
  flex-direction row
  align-items center
  justify-content space-between

  box-sizing border-box
  height 100%
}

.nav-layout__logo {
  box-sizing border-box
  max-height 100%
}

.nav-layout__home-label {
  overflow hidden
  display block
  width 0
  height 0
  opacity 0
}

.nav-layout__links {
  position absolute
  top 100%
  left 0

  display flex
  flex-direction column
  box-sizing border-box

  overflow hidden
  width 100%
  height 0

  text-align center
  background-color white

  .nav-layout--open & {
    height auto
    +desktop-version() {
      height 100%
    }
  }

  +desktop-version() {
    position static
    flex-direction row
    width auto
    height 100%
    text-align left
  }
}

.nav-layout__burger {
  +desktop-version() {
    display none
  }
}
</style>
