<template>
  <div v-if="data.analytics?.trackingID && choice === undefined" class="cookies">
    <p v-dompurify-html="$md2Html(data.analytics?.content || 'Ce site utilise des cookies')" />
    <div class="cookies__actions">
      <button class="cookies__actions-revoke" @click="choice = -1; revokeConsent()">
        {{ data.analytics?.revokeLabel || "Refuser" }}
      </button>
      <button class="cookies__actions-accept" @click="choice = 1; grantConsent(data.analytics.trackingID)">
        {{ data.analytics?.acceptLabel || "Accepter" }}
      </button>
    </div>
  </div>
</template>

<script setup>
  const { choice, grantConsent, revokeConsent } = useAnalytics();
  const {data} = await useAPIConfig();
</script>

<style lang="stylus" scoped>

.cookies {
    z-index 100
    width calc(100vw-40px)
    max-width 540px

    padding 4px 20px 20px;

    background-color white;
    position fixed
    bottom 0
    right 0

    border-top-left-radius 7px

    box-shadow -5px -5px 5px rgba(0, 0, 0, 0.1);
    transition: opacity 250ms ease-out 250ms

    p {
      margin 0
    }

    .cookies__actions {
      margin 20px 20px 0;
      display flex;
      justify-content space-between

      +size(S){
        width calc(100%-40px);

      }
    }

    button {
      @extends $reset-button !optional
      min-width 35vw
      
      cursor pointer
      +size(S){
        min-width 190px
      }
    }
    button.cookies__actions-accept {
      @extends $black-button
      &:hover {
        @extends $white-button
      }
    }
    button.cookies__actions-revoke {
      @extends $white-button
      &:hover {
        @extends $black-button
      }
    }
}
</style>