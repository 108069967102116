<template>
  <NuxtLink :to="propFromType.href">
    <img
      :src="propFromType.src"
      :alt="propFromType.label"
      :width="propFromType.width"
      :height="propFromType.height"
    >
  </NuxtLink>
</template>

<script>
export default {
  props: {
    type: { type: String, required: true },
  },

  computed: {
    propFromType() {
      const getSrc = filename => `/medias/${filename}.svg`;

      switch (this.type.toLowerCase()) {
        case 'facebook':
        case 'fb':
          return {
            label: 'facebook',
            src: getSrc('picto-social-fb'),
            href: 'https://www.facebook.com/matrice.io/',
            width: 25,
            height: 25,
          };
        case 'instagram':
        case 'insta':
          return {
            label: 'instagram',
            src: getSrc('picto-social-insta'),
            href: 'https://www.instagram.com/matrice.io/',
            width: 25,
            height: 25,
          };
        case 'linkedin':
          return {
            label: 'linkedin',
            src: getSrc('picto-social-linkedin'),
            href: 'https://fr.linkedin.com/school/matriceio/',
            width: 25,
            height: 25,
          };
        case 'tw':
        case 'twitter':
          return {
            label: 'twitter',
            src: getSrc('picto-social-twitter'),
            href: 'https://twitter.com/matrice_io',
            width: 25,
            height: 21,
          };
        default:
          return {label: this.type, src: undefined, href:undefined};
      }
    },
  },
};
</script>
