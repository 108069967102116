<template>
  <Teleport v-if="content" to=".page__panel">
    <ThePanel class="contact" @close="$emit('close')">
      <UiMediaImage
        v-if="content.cover"
        :image="content.cover"
        :ratio="360/270"
        width="400"
        fit
        class="contact__cover"
      />

      <template v-for="(group, groupIndex) in content.links" :key="`links-${groupIndex}`">
        <p class="contact__item contact__item--title typo-h2">
          {{ group.title }}
        </p>

        <template v-for="(link, linkIndex) in group.list" :key="`links-${linkIndex}`">
          <div
            :class="['contact__item', { 'contact__item--copied': linkIndex === lastCopiedIndex} ]"
            @click="copy(link.to, linkIndex)"
          >
            <span class="contact__item-label">{{ link.label }}</span>
          </div>
        </template>
      </template>
    </ThePanel>
  </Teleport>
</template>

<script setup>
import { getMediaFromData } from '~/data/media.js';

defineEmits(['close']);

const lastCopiedIndex = ref(-1);

const {data:config} = await useAPIConfig();
const content = computed(() => {
  const data = config.value.contacts;
  if (!data) return;

  const cover = getMediaFromData(data.cover);
  const links = [{
    title: data.title,
    list: data?.emails?.map(contact => ({
      label: contact.label,
      to: contact.email,
    })),
  }];

  return { cover, links };
});

const copy = (text, index) => {
  if (typeof navigator !== 'undefined' && !!navigator.clipboard) {
    navigator.clipboard.writeText(text);
    lastCopiedIndex.value = index;
    setTimeout(() => { lastCopiedIndex.value = -1 }, 800);
  }
};
</script>

<style lang="stylus" scoped>
.contact {
  height: 100vh;
}

.contact__cover {
  height 300px
}

.contact__item {
  position relative
  display block

  box-sizing border-box
  padding 30px 70px 30px 30px
  margin 0

  font-weight normal
  text-decoration none
  line-height 1

  border-bottom 1px solid $cl-separator
  cursor pointer

  &:before
  &:after {
    content ''

    position absolute
    right 30px
    top 50%

    transform translateY(-50%)
    transition transform 0.25s, opacity 0.25s
  }

  &:before {
    content 'copié'
    opacity 0
  }

  &:after {
    width 36px
    height @width
    background-image embedurl('~/assets/images/mail-link.svg', 'utf8')
  }

  &:hover {
    text-decoration underline
    &:after {
      transform translateY(-50%) scale(0.8)
    }
  }
}

.contact__item--copied {
  &:before {
    opacity 1
  }
  &:after {
    opacity 0
  }
}

.contact__item--title {
  pointer-events none
  &:before
  &:after {
    display none
  }
}
</style>
